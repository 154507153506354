import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/board',
    name: 'board',
    component: () => import(/* webpackChunkName: "board" */ '../views/Board.vue')
  },
  // {
  //   path: '/mentoring',
  //   name: 'mentoring',
  //   component: () => import(/* webpackChunkName: "mentoring" */ '../views/Mentoring.vue')
  // },
  {
    path: '/scholarship',
    name: 'scholarship',
    component: () => import(/* webpackChunkName: "scholarship" */ '../views/Scholarship.vue')
  },
  {
    path: '/authorized-training-centers',
    name: 'atc',
    component: () => import(/* webpackChunkName: "atc" */ '../views/Atc.vue')
  },
  {
    path: '/kathy-stanford-grant',
    name: 'grant',
    component: () => import(/* webpackChunkName: "grant" */ '../views/Grant.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/joyful-movement',
    name: 'joyful-movement',
    component: () => import(/* webpackChunkName: "joyful-movement" */ '../views/JoyfulMovement.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <main class="home">
    <section class="intro">
      <img
        src="@/assets/images/banner-home@1x.jpg"
        srcset="
          @/assets/images/banner-home@1x.jpg 1x,
          @/assets/images/banner-home@2x.jpg 2x,
          @/assets/images/banner-home@3x.jpg 3x
        "
        alt="Photo of woman working out on a Reformer"
        class="banner"
      />
      <div class="intro-content">
        <h1 class="h1 narrow">Countering Racism in our Community</h1>
        <h1 class="h2 wide">Countering Racism in our Community</h1>
        <hr>
        <p class="p2">As our society faces racial justice and equality issues, Balanced Body is examining how we can help change the face of Pilates, by increasing the number of Black Pilates professionals and Black clients and practitioners. We recognize that, in the United States, people of African descent have been systematically disenfranchised for over 400 years. And while we recognize it will take time and perseverance, Balanced Body is committed to helping drive real, tangible change in the Pilates industry.</p>
      </div>
    </section>

    <section class="primary">
      <div class="primary-inner">
        <h1 class="h1">Diversity in Pilates</h1>
        <p class="p1">And specific to our industry, Pilates has been perceived as the domain of wealthy white women (and occasionally white men) since its inception. This led to very few professional Pilates education opportunities for people of color. It also created a dearth of Black clients as Pilates was not seen as Black community-friendly, and often cost prohibitive. Today, thanks largely to Black Instructors who have persevered and made Pilates their own, and a slew of Black athletes, actors and musicians who’ve popularized Pilates in the Black community, diversity has started appearing in Pilates.</p>
        <p class="p1">As the largest manufacturer of Pilates equipment globally, and one of the biggest Pilates education programs in the world, Balanced Body has identified opportunities to expand career opportunities for Black instructors through our programs. We believe by creating more Black teachers, we will also be creating “I see myself in that person” role models and more safe and welcoming Pilates spaces for Black clients.</p>
      </div>
      <span class="triangle" />
    </section>
    
    <section class="test">
      <video width="100%" autoplay loop muted playsinline>
        <source src="https://s3.amazonaws.com/s3.pilates.com/scholarship/BLM_BANNER_2.webm" type="video/webm">
        <source src="https://s3.amazonaws.com/s3.pilates.com/scholarship/BLM_BANNER_2.mp4" type="video/mp4">
      </video>
    </section>

    <!-- <section class="teaser one">
      <div class="teaser-inner">
        <h2 class="h2">Mentoring Program</h2>
        <p class="p2">Leveraging Experience and Expertise to Help Shape the Future</p>
        <hr>
        <h5 class="h5"><router-link to="/mentoring">Learn more <span>→</span></router-link></h5>
      </div>
      <img src="@/assets/images/img-home1@1x.png" srcset="@/assets/images/img-home1@1x.png 1x, @/assets/images/img-home1@2x.png 2x, @/assets/images/img-home1@3x.png 3x" alt="Man working out on Reformer">
    </section> -->

    <section class="teaser two">
      <div class="teaser-inner">
        <h2 class="h2">Scholarship Program</h2>
        <p class="p2">Making Pilates Education Accessible and Welcoming for All</p>
        <hr>
        <h5 class="h5"><router-link to="/scholarship">Read more <span>→</span></router-link></h5>
      </div>
      <img src="@/assets/images/img-home2@1x.png" srcset="@/assets/images/img-home2@1x.png 1x, @/assets/images/img-home2@2x.png 2x, @/assets/images/img-home2@3x.png 3x" alt="Instructor working with student on equipment">
      <span class="triangle" />
    </section>
  </main>
</template>

<script>
  export default {
    name: "Home",

    metaInfo: {
      title: "Countering Racism in our Community",
      titleTemplate: "%s | Diversity in Pilates",
      meta: [
        {
          name: "description",
          content: "As our society faces racial justice and equality issues, Balanced Body is examining how we can help change the face of Pilates, by increasing the number of Black Pilates professionals and Black clients and practitioners."
        }
      ]
    },
  }
</script>

<style scoped>
.narrow {
  display: block;
}

.wide {
  display: none;
}

.intro {
  margin-bottom: 50px;
}

.banner {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.intro-content {
  padding: 35px 34px 35px 32px;
}

hr {
  border: none;
  border-top: 4px dotted #9EB675;
  margin: 40px 0 20px;
  width: 158px;
}

.primary {
  background-color: var(--warm1);
  overflow: hidden;
}

.primary-inner {
  padding: 64px 32px 36px;
  max-width: 764px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.primary-inner .h1 {
  color: var(--event5);
  margin-bottom: 12px;
}

.primary-inner .p1 {
  margin-bottom: 28px;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: max(268px, 40vw) 0 0 max(324px, 50vw);
  border-color: transparent transparent transparent var(--warm2);
}

.teaser {
  padding: 65px 32px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.teaser.two {
  position: relative;
  overflow: hidden;
  background-color: var(--warm3);
}

.teaser img {
  width: 100%;
  position: relative;
  z-index: 10;
  max-width: 380px;
}

.teaser-inner {
  position: relative;
  z-index: 10;
  max-width: 426px;
}

.teaser.two .h2 {
  color: var(--white);
}

.teaser.one hr {
  border-top: 4px dotted #abab78;
  margin: 24px 0 20px;
}

.teaser.two hr {
  border-top: 4px dotted #ecf2c6;
  margin: 24px 0 20px;
}

.one .p2 {
  color: #555658;
  margin-top: 15px;
}

.two .p2 {
  color: #fff;
  margin-top: 15px;
}

.one .h5 a {
  text-decoration: none;
  color: var(--link);
}

.two .h5 a {
  text-decoration: none;
  color: #9ad8df;
}

.teaser .h5 a:hover {
  text-decoration: underline;
}

.teaser .h5 {
  margin-top: 20px;
  margin-bottom: 30px;
}

.teaser .h5 span {
  vertical-align: 12%;
}

.teaser .triangle {
  position: absolute;
  bottom: 0;
  left: 0;
  border-color: transparent transparent transparent var(--warm4);
}

video {
  filter: grayscale(1);
}

video:hover {
  filter: none;
}

@media only screen and (min-width: 768px) {
  .narrow {
    display: none;
  }

  .wide {
    display: block;
  }

  .intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1052px;
    margin: 0 auto 50px;
    padding: 0 min(calc(1084px - 100vw), 32px);
  }

  hr {
    margin: 50px 0 20px;
  }

  .intro-content {
    order: 1;
    width: 41%;
    padding: 0;
  }

  .banner {
    order: 2;
    width: 57%;
    max-width: 607px;
    height: 535px;
  }

  .primary-inner {
    padding: 90px 32px 62px;
  }

  .teaser {
    flex-flow: row nowrap;
    justify-content: center;
    height: 460px;
  }

  .one .teaser-inner{
    order: 2;
  }

  .one img {
    order: 1;
  }

  .teaser.one hr,
  .teaser.two hr {
    margin: 38px 0 0;
  }

  .teaser .h5 {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
</style>
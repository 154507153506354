<template>
  <div>
    <header class="wide">
      <nav class="header-nav-wide">
        <router-link to="/">Home</router-link>
        <!-- <router-link to="/mentoring">Mentoring</router-link> -->
        <router-link to="/scholarship">Scholarship</router-link>
        <a href="https://www.pilates.com/" class="logo-link">
          <img src="@/assets/images/logo-bb-mark.svg" alt="Balanced Body mark" class="logo" />
        </a>
        <router-link to="/board">Advisory Board</router-link>
        <router-link to="/kathy-stanford-grant">Kathy Stanford Grant</router-link>
      </nav>
    </header>

    <header class="narrow">
      <a href="https://www.pilates.com/">
        <img src="@/assets/images/logo-bb-mark.svg" alt="Balanced Body mark" class="logo" />
      </a>
      <!-- <slide :width="screenWidth" :closeOnNavigation="true"> -->
      <slide :width="screenWidth" :closeOnNavigation="true">
        <nav class="header-nav-narrow">
          <router-link to="/">Home</router-link>
          <!-- <router-link to="/mentoring">Mentoring</router-link> -->
          <router-link to="/scholarship">Scholarship</router-link>
          <router-link to="/board">Advisory Board</router-link>
          <router-link to="/kathy-stanford-grant">Kathy Stanford Grant</router-link>
          <router-link to="/contact">Contact Us</router-link>
        </nav>
      </slide>
    </header>
  </div>
</template>

<script>
import { Slide } from 'vue-burger-menu'
  export default {
    name: "AppHeader",

    components: {
        Slide
    },

    computed: {
      screenWidth() {
        return screen.width.toString();
      }
    }
  }
</script>

<style scoped>
.narrow {
  display: block;
  height: 100px;
}

.wide {
  display: none;
}

.header-nav-wide {
  max-width: 1030px;
  height: 125px;
  margin: 0 auto;
  padding: 0 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.header-nav-wide a,
.header-nav-narrow a {
  text-decoration: none;
  font-family: var(--bry-pro-med);
  font-size: 14rem;
  font-weight: 500;
  line-height: 1;
  color: #555658;
  text-transform: uppercase;
  white-space: nowrap;
}

.header-nav-wide a:hover {
  text-decoration: underline;
}

.logo {
  width: clamp(45px, 8vw, 65px);
}

.logo-link {
  width: 65px;
}

.narrow .logo {
  display: block;
  margin: 0 auto;
  padding-top: 25px;
}

.header-nav-narrow {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: 25vh;
}

.header-nav-narrow a {
  font-size: 18rem;
  border-bottom: 1px solid #cacaca;
  padding: 20px 0;
  margin-right: 20px;
}

.header-nav-narrow a:first-of-type {
  border-top: 1px solid #cacaca;
}

.router-link-exact-active {
  text-decoration: underline !important;
}

::v-deep .bm-menu {
  background-color: var(--white);
}

::v-deep .bm-item-list {
  margin-left: 5%;
}

::v-deep .bm-burger-button {
  right: 36px;
  left: auto;
}

::v-deep .line-style {
  height: 10%;
}

::v-deep .bm-cross {
  background: #555658;
  width: 2px !important;
  height: 37px !important;
  transform: rotate( -45deg);
}

::v-deep .bm-cross:nth-last-of-type() {
  transform: rotate(45deg) !important;
}

@media only screen and (min-width: 768px) {
  .narrow {
    display: none;
  }

  .wide {
    display: block;
  }
}
</style>